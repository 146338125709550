.App {
  text-align: center;
  background-color: #0E1B22;
  height: 100vh;
  max-height: 100vh;
}

.App-header {
  height: 7vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-menu {
  margin-left: 1rem;
  display: flex;
  height: 30px;
  fill: whitesmoke;
}

.App-logo{
  display: flex;
}

.App-question{
  margin-right: 1rem;
  display: flex;
  height: 30px;
  fill: whitesmoke;
}


.App-link {
  color: #61dafb;
}

.line {
  width: 100%;
  height: 2px;
  background-color: #0D2731;
  margin: 0;
  padding: 0;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 65vh;
}

.image-container img {
  max-height: 100%;
  max-width: 100%;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  width: 100vw;
  position: fixed;
  bottom: 0;
}

.button-container > button{
  flex-basis: 45%;
  padding: 1rem;
  margin-bottom: 0.75rem;
  border-radius: 15px;
}

.info-container {
  text-align: center;
  width: 80vw;
  height: 60vh;
  position: fixed;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%); 
  background-color: #ebebeb;
  border-radius: 10px;
  overflow-wrap: break-word;
}
.cancel-button{
  background-color: #ffffff;
  border: none;
  border-radius: 10px;
  padding: 0.5rem;
  position: fixed;
  right: 0;
  top: 0;
}